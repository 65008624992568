import api from '../../../shared/services/api';

import {
    USER_SIGN_IN,
    USER_LOG_OFF,
    USER_LOADING,
    GenericAction,
    AuthState,
} from '../../types';

const initialState: AuthState = {
    isAuthenticated: false,
    loading: false,
    user: null,
};

const key = 'pikachu';

export default function (state = initialState, action: GenericAction) {
    switch (action.type) {
        case USER_SIGN_IN:
            sessionStorage.setItem(key, action.payload.token);

            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
            };
        case USER_LOADING:

            return {
                ...state,
                loading: action.payload.loading
            };
        case USER_LOG_OFF:
            sessionStorage.removeItem(key);

            return {
                ...initialState,
            };
        default:
            return state;
    }
}

export const fetchUserInfo = () => async dispatch => {
    const token = sessionStorage.getItem(key);

    if (token !== null) {
        await api
            .get('/users/me', {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(response => {
                dispatch({
                    type: 'USER_SIGN_IN',
                    payload: { token, ...response.data },
                });
            });
    }
};
