import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import RouteProgressIndicator from './shared/components/RouteProgressIndicator';
import { appHistory } from './helpers/appHistory';
import { useDispatch } from 'react-redux';
import { fetchUserInfo } from './redux/reducers/auth/authReducer';

const publicModule = lazy(() => import('./modules/public/index'));

const MainRouter: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserInfo());
    }, []) // eslint-disable-line

    return (
        <Router history={appHistory}>
            <Suspense fallback={<RouteProgressIndicator />}>
                <Switch>
                    <Route path="/" component={publicModule} />
                </Switch>
            </Suspense>
        </Router>
    );
};

export default MainRouter;
