import { GenericAction, HeaderState, HEADER_CHANGE_TITLE } from '../../types';

const initialState: HeaderState = {
    headerTitle: 'Central de Vagas',
};

export default function (state = initialState, action: GenericAction) {
    switch (action.type) {
        case HEADER_CHANGE_TITLE:
            return {
                ...state,
                headerTitle: action.payload,
            };
        default:
            return state;
    }
}
