import React from 'react';
import MainRouter from './mainRouter';
import AppTheme from './shared/components/ApplicationTheme';
import ProgressIndicator from './shared/components/ProgressIndicator';

const App: React.FC = () => (
    <AppTheme>
        <ProgressIndicator>
            <MainRouter />
        </ProgressIndicator>
    </AppTheme>
);
    
export default App;
