import { PaletteType } from "@material-ui/core";
import { GenericAction, THEME_CHANGE_COLORS, THEME_CHANGE_TYPE, ThemeState } from "../../types";

const loadType = (): PaletteType => {
    const localStorageOpinion: any = localStorage.getItem('themeType');
    
    if (localStorageOpinion !== null) {
        return localStorageOpinion;
    }
    else {
        return 'light';
    }
} 


const initialState: ThemeState = {
    colors: {
        primaryColor: '#3B72A7',
        secondaryColor: '#4E8F48',
    },
    type: loadType()
}

const themeReducer = (state = initialState, action: GenericAction): ThemeState => {

    switch (action.type) {
        case THEME_CHANGE_COLORS:
            return {
                ...state,
                colors: action.payload
            }
        case THEME_CHANGE_TYPE:
            const type = state.type === 'dark' ? 'light' : 'dark';
            localStorage.setItem('themeType', type);

            return {
                ...state,
                type
            }
        default:
            return state;
    }

}

export default themeReducer;
