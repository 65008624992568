import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import classificationReducer from './classification/classificationReducer';
import enrollmentReducer from './enrollment/enrollmentReducer';
import headerReducer from './header/headerReducer';
import progressIndicatorReducer from './progressIndicator/progressIndicatorReducer';
import schoolUnitReducer from './schoolUnit/schoolUnitReducer';
import themeReducer from './theme/themeReducer';

const rootReducer = combineReducers({
    authReducer,
    classificationReducer,
    enrollmentReducer,
    headerReducer,
    progressIndicatorReducer,
    schoolUnitReducer,
    themeReducer,
});

export default rootReducer;
