import {
    SchoolUnitState,
    SCHOOLUNITS_LOAD,
    GenericAction,
    SCHOOLUNITS_TOGGLE_SUCCESS,
    SCHOOLUNITS_TOGGLE_ERROR,
    SCHOOLUNIT_SELECT,
} from '../../types';

import api from '../../../shared/services/api';
import store from '../../store';

const initialState: SchoolUnitState = {
    data: [],
    selected: '',
    isEmpty: true,
    isLoaded: false,
    success: 'no',
    error: false,
};

export default function (state = initialState, action: GenericAction) {
    switch (action.type) {
        case SCHOOLUNITS_LOAD:
            const data = [...action.payload];

            let isEmpty = true;
            if (action.payload.length !== 0) {
                isEmpty = false;
            }

            return {
                ...state,
                data,
                isEmpty,
                isLoaded: true,
            };

        case SCHOOLUNITS_TOGGLE_SUCCESS:
            return {
                ...state,
                success: action.payload,
            };

        case SCHOOLUNITS_TOGGLE_ERROR:
            return {
                ...state,
                error: !state.error,
            };

        case SCHOOLUNIT_SELECT:
            return {
                ...state,
                selected: action.payload,
            };

        default:
            return state;
    }
}

export const fetchSchoolUnits = () => async dispatch => {
    const token = store.getState().authReducer.user.token;

    await api
        .get(`/schoolunits`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
            let formattedResponse = response.data;

            for (let i = 0; i < formattedResponse.length; i++) {
                formattedResponse[i].totalVacancy = 0;

                for (
                    let j = 0;
                    j < formattedResponse[i].classrooms.length;
                    j++
                ) {
                    formattedResponse[i].totalVacancy +=
                        formattedResponse[i].classrooms[j].vacancy;
                }

                formattedResponse[i].id = formattedResponse[i].guid;
            }

            // console.log(formattedResponse);

            dispatch({
                type: 'SCHOOLUNITS_LOAD',
                payload: formattedResponse,
            });
        });
};

export const createSchoolUnit = (data: any) => async dispatch => {
    const token = store.getState().authReducer.user.token;

    await api
        .post(
            '/schoolunits',
            { ...data },
            { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(response => {
            dispatch(fetchSchoolUnits());

            dispatch({
                type: 'SCHOOLUNITS_TOGGLE_SUCCESS',
                payload: 'create',
            });
        })
        .catch(error => {
            console.error(error);

            dispatch({
                type: 'SCHOOLUNITS_TOGGLE_ERROR',
            });
        });
};

export const updateSchoolUnit = (data: any) => async dispatch => {
    const token = store.getState().authReducer.user.token;

    await api
        .put(
            `/schoolunits/${data.guid}`,
            { ...data },
            { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(response => {
            // console.log(response);

            dispatch(fetchSchoolUnits());

            dispatch({
                type: 'SCHOOLUNITS_TOGGLE_SUCCESS',
                payload: 'update',
            });
        })
        .catch(error => {
            console.error(error);

            dispatch({
                type: 'SCHOOLUNITS_TOGGLE_ERROR',
            });
        });
};
