import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { CircularProgress } from '@material-ui/core';


const useStyle = makeStyles(theme => ({
    container: {        
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const RouteProgressIndicator: React.FC = () => {

    const classes = useStyle();

    return (
        <div className={classes.container}>
            <CircularProgress />
        </div>
    );
}

export default RouteProgressIndicator;