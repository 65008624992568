import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Box, Card, CircularProgress, Typography } from '@material-ui/core';
import { RootState } from '../../../redux/types';

interface ProgressIndicatorProps {
    ids?: Array<string>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: ReactElement<any, any>
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = (props) => {

    const ids = useSelector((state: RootState) => state.progressIndicatorReducer.onFetchingData);

    const { children } = props;

    return (
        <div>
            <Backdrop style={{ zIndex: 9999 }} open={(ids?.length ?? 0) > 0}>
                <Card>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        p={4}
                    >
                        <CircularProgress color="primary" />
                        <Box pt={2} ml={2} mr={2}>
                            <Typography variant="body2">
                                Por favor aguarde...
                            </Typography>
                        </Box>
                    </Box>
                </Card>
            </Backdrop>
            {children}
        </div>
    )

}

export default ProgressIndicator;