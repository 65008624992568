import api from '../../../shared/services/api';
import store from '../../store';
import {
    GenericAction,
    EnrollmentState,
    ENROLLMENT_SUCCESS,
    ENROLLMENT_RESET,
    ENROLLMENT_LANDING_READ,
    ENROLLMENTS_LOAD,
} from '../../types';

const initialState: EnrollmentState = {
    isSuccessful: false,
    successData: [],
    sentEnrollmentsData: [],
    isLoading: true,
    landingRead: false,
};

export default function (state = initialState, action: GenericAction) {
    switch (action.type) {
        case ENROLLMENT_SUCCESS:
            return {
                ...state,
                isSuccessful: true,
                successData: action.payload,
            };
        case ENROLLMENT_RESET:
            return {
                ...state,
                ...initialState,
            };
        case ENROLLMENT_LANDING_READ:
            return {
                ...state,
                landingRead: true,
            };
        case ENROLLMENTS_LOAD:
            return {
                ...state,
                sentEnrollmentsData: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

export const fetchEnrollments = () => async dispatch => {
    const token = store.getState().authReducer.user.token;

    await api
        .get(`/users/enrollments`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
            const minimumWage = 1045;
            let enrollmentsArr = [...response.data];
            enrollmentsArr.forEach((enrollment, index) => {
                switch (enrollment.cityResident) {
                    case 0:
                        enrollment.cityResident = 'Não';
                        break;
                    case 1:
                        enrollment.cityResident = 'Sim';
                        break;
                }

                switch (enrollment.responsibleForChild) {
                    case 0:
                        enrollment.responsibleForChild = 'Pais';
                        break;
                    case 1:
                        enrollment.responsibleForChild =
                            'Crianças cuja a reponsabilidade é de terceiros';
                        break;
                    case 2:
                        enrollment.responsibleForChild =
                            'Situação de vulnerabilidade social e/ou risco pessoal, encaminhadas pelos órgãos públicos';
                        break;
                }

                switch (enrollment.familyIncome) {
                    case 0:
                        enrollment.familyIncome = `Renda de até R$ ${(
                            minimumWage * 0.5
                        ).toFixed(2)}`;
                        break;
                    case 1:
                        enrollment.familyIncome = `Renda de R$ ${(
                            minimumWage * 0.6
                        ).toFixed(2)} até R$ ${minimumWage.toFixed(2)}`;
                        break;
                    case 2:
                        enrollment.familyIncome = `Renda de R$ ${(
                            minimumWage * 1.1
                        ).toFixed(2)} até R$ ${(minimumWage * 1.3).toFixed(2)}`;
                        break;
                    case 3:
                        enrollment.familyIncome = `Renda de R$ ${(
                            minimumWage * 1.4
                        ).toFixed(2)} até R$ ${(minimumWage * 1.3).toFixed(2)}`;
                        break;
                    case 4:
                        enrollment.familyIncome = `Renda acima de R$ ${(
                            minimumWage * 1.6
                        ).toFixed(2)}`;
                        break;
                }

                switch (enrollment.familyHousing) {
                    case 0:
                        enrollment.familyHousing = 'Família possui imóvel';
                        break;
                    case 1:
                        enrollment.familyHousing =
                            'Família reside em casa cedida';
                        break;
                    case 2:
                        enrollment.familyHousing =
                            'Família paga financiamento do imóvel';
                        break;
                    case 3:
                        enrollment.familyHousing =
                            'Família reside em casa alugada';
                        break;
                }

                switch (enrollment.socialProgram) {
                    case 0:
                        enrollment.socialProgram = 'Possui Bolsa Família';
                        break;
                    case 1:
                        enrollment.socialProgram = 'Não possui Bolsa Família';
                        break;
                }

                switch (enrollment.motherOccupation) {
                    case 0:
                        enrollment.motherOccupation =
                            'Mães que trabalham fora do lar';
                        break;
                    case 1:
                        enrollment.motherOccupation =
                            'Mães que trabalham com faxina todos os dias da semana';
                        break;
                    case 2:
                        enrollment.motherOccupation =
                            'Mães que trabalham com faxina alguns dias da semana';
                        break;
                    case 3:
                        enrollment.motherOccupation =
                            'Mães que não trabalham fora do lar';
                        break;
                }

                switch (enrollment.familyChildrenQty) {
                    case 0:
                        enrollment.familyChildrenQty = 'Até 2 filhos';
                        break;
                    case 1:
                        enrollment.familyChildrenQty = 'Até 4 filhos';
                        break;
                    case 2:
                        enrollment.familyChildrenQty = 'A partir de 5 filhos';
                        break;
                }

                enrollment.cpf = enrollment.cpf.replace(
                    /^(\d{3})(\d{3})(\d{1,3})(\d{1,2})$/g,
                    '$1.$2.$3-$4',
                );

                enrollment.birthDate = enrollment.birthDate.split('T', 1);
            });

            dispatch({
                type: 'ENROLLMENTS_LOAD',
                payload: enrollmentsArr,
            });
        });
};

export const cancelEnrollment = (guid: string) => async dispatch => {
    const token = store.getState().authReducer.user.token;

    await api
        .delete(`/enrollments/${guid}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
            dispatch(fetchEnrollments());
        });
};
