const {
    REACT_APP_API_URL,
    REACT_APP_BASE_URL,
    REACT_APP_ENV,
    REACT_APP_API_KEY,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_DATABASE_URL,
    REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET,
    REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_APP_ID,
    REACT_APP_MEASUREMENT_ID,
    REACT_APP_GOOGLE_API_KEY,
} = process.env;

const isProduction = REACT_APP_ENV === 'production';
const isDevelopment = REACT_APP_ENV === 'development';
const isLocal = REACT_APP_ENV === 'local';

export const env = {
    REACT_APP_API_URL,
    REACT_APP_BASE_URL,
    REACT_APP_ENV,
    REACT_APP_API_KEY,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_DATABASE_URL,
    REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET,
    REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_APP_ID,
    REACT_APP_MEASUREMENT_ID,
    REACT_APP_GOOGLE_API_KEY,
    isProduction,
    isDevelopment,
    isLocal
}
