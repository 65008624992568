import { PaletteType } from '@material-ui/core';
import rootReducer from './reducers';
import store from './store';

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export interface GenericAction {
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
}

export interface ProgressIndicatorState {
    onFetchingData: Array<string>;
}

export const PROGRESS_INDICATOR_ADD = 'PROGRESS_INDICATOR_ADD';
export const PROGRESS_INDICATOR_REMOVE = 'PROGRESS_INDICATOR_REMOVE';

export interface ThemeColors {
    primaryColor: string;
    secondaryColor: string;
}

export interface ThemeState {
    colors: ThemeColors;
    type: PaletteType;
}

export const THEME_CHANGE_COLORS = 'THEME_CHANGE_COLORS';
export const THEME_CHANGE_TYPE = 'THEME_CHANGE_DARK';

export interface AuthState {
    isAuthenticated: boolean;
    loading: boolean;
    user: UserInfo | null;
}

export interface UserInfo {
    guid: string;
    name: string;
    email: string;
    cpf: string;
    cellphone: string;
    role: string;
    token: string;
}

export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_LOG_OFF = 'USER_LOG_OFF';
export const USER_LOADING = 'USER_LOADING';

export interface HeaderState {
    headerTitle: string;
}

export const HEADER_CHANGE_TITLE = 'HEADER_CHANGE_TITLE';

export interface EnrollmentState {
    isSuccessful: boolean;
    successData: any[];
    sentEnrollmentsData: any[];
    isLoading: boolean;
    landingRead: boolean;
}

export const ENROLLMENT_LANDING_READ = 'ENROLLMENT_LANDING_READ';
export const ENROLLMENT_SUCCESS = 'ENROLLMENT_SUCCESS';
export const ENROLLMENT_RESET = 'ENROLLMENT_RESET';
export const ENROLLMENTS_LOAD = 'ENROLLMENTS_LOAD';

export interface SchoolUnit {
    guid: string;
    id: SchoolUnit['guid'];
    name: string;
    position: string;
    countryside: number;
    totalVacancy: number;
}

export interface SchoolUnitState {
    data: SchoolUnit[];
    selected: SchoolUnit['guid'];
    isEmpty: boolean;
    isLoaded: boolean;
    success: 'no' | 'create' | 'update';
    error: boolean;
}

export const SCHOOLUNITS_LOAD = 'SCHOOLUNITS_LOAD';
export const SCHOOLUNIT_SELECT = 'SCHOOLUNIT_SELECT';
export const SCHOOLUNITS_TOGGLE_SUCCESS = 'SCHOOLUNITS_TOGGLE_SUCCESS';
export const SCHOOLUNITS_TOGGLE_ERROR = 'SCHOOLUNITS_TOGGLE_ERROR';

export interface ClassificationState {
    data: any[];
    selected: string | Object;
    selectedSchool: string | Object;
    previousSuccessfulSchool: Object;
    classrooms: any[];
    searchClassrooms: any[];
    emptyClassrooms: boolean;
    loadedClassrooms: boolean;
    selectedClassroom: Object;
    searchField: string;
    searchFilter: string;
    searchSchool: string;
    isEmpty: boolean;
    isLoaded: boolean;
    success: 'no' | 'update';
    error: boolean;
    stalemate: any[];
}

export const CLASSIFICATION_LOAD = 'CLASSIFICATION_LOAD';
export const CLASSIFICATION_LOAD_CLASSROOMS = 'CLASSIFICATION_LOAD_CLASSROOMS';
export const CLASSIFICATION_LOAD_SEARCH_CLASSROOMS =
    'CLASSIFICATION_LOAD_SEARCH_CLASSROOMS';
export const CLASSIFICATION_SELECT = 'CLASSIFICATION_SELECT';
export const CLASSIFICATION_PREP_CLASSROOMS_RELOAD =
    'CLASSIFICATION_PREP_CLASSROOMS_RELOAD';
export const CLASSIFICATION_TOGGLE_SUCCESS = 'CLASSIFICATION_TOGGLE_SUCCESS';
export const CLASSIFICATION_TOGGLE_ERROR = 'CLASSIFICATION_TOGGLE_ERROR';
export const CLASSIFICATION_RESET = 'CLASSIFICATION_RESET';
export const CLASSIFICATION_CHANGE_SCHOOL = 'CLASSIFICATION_CHANGE_SCHOOL';
export const CLASSIFICATION_CHANGE_LAST_SCHOOL =
    'CLASSIFICATION_CHANGE_LAST_SCHOOL';
export const CLASSIFICATION_CHANGE_CLASSROOM =
    'CLASSIFICATION_CHANGE_CLASSROOM';
export const CLASSIFICATION_CHANGE_STATUS = 'CLASSIFICATION_CHANGE_STATUS';
export const CLASSIFICATION_CHANGE_FILTER = 'CLASSIFICATION_CHANGE_FILTER';
export const CLASSIFICATION_CHANGE_SEARCH = 'CLASSIFICATION_CHANGE_SEARCH';
export const CLASSIFICATION_CHANGE_SEARCH_SCHOOL =
    'CLASSIFICATION_CHANGE_SEARCH_SCHOOL';
export const CLASSIFICATION_CHANGE_STALEMATE =
    'CLASSIFICATION_CHANGE_STALEMATE';
export const CLASSIFICATION_CLEAR = 'CLASSIFICATION_CLEAR';
