import React, { ReactElement } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState, ThemeState } from '../../../redux/types';


export interface AppThemeProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: ReactElement<any, any>
}

const AppTheme: React.FC<AppThemeProps> = (props) => {

    const theme: ThemeState = useSelector((state: RootState) => state.themeReducer);

    const { children } = props;
    const { colors, type } = theme;

    const appTheme = createMuiTheme({

        palette: {
            type,
            primary: {
                main: colors.primaryColor,
            },
            secondary: {
                main: colors.secondaryColor,
            },
        },
    });

    return (
        <div>
            <ThemeProvider theme={appTheme}>
                {children}
            </ThemeProvider>
        </div>
    );
}

export default AppTheme;